<template>
  <a-drawer
    :title="model.id > 0?'编辑百科':'添加百科'"
    :maskClosable="false"
    width="800"
    placement="right"
    :closable="true"
    @close="hide()"
    :visible="isShow"
  >
    <a-form>
      <a-form-item label="图片" v-bind="formItemLayout">
        <image-upload v-model="model.logo" hint="建议尺寸200*200px以上"></image-upload>
      </a-form-item>

      <a-form-item label="类型" v-bind="formItemLayout" v-if="model.id > 0">
        <a-input v-model="model.tag" readOnly/>
      </a-form-item>

      <a-form-item label="类型" v-bind="formItemLayout" v-else>
        <a-select v-model="model.tag"
                  style="width: 100%"
                  @select="changeType"
        >
          <a-select-option value="产品知识">产品知识</a-select-option>
          <a-select-option value="新品上市">新品上市</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="标题" v-bind="formItemLayout">
        <a-input v-model="model.title"/>
      </a-form-item>

      <!--      <a-form-item label="简介" v-bind="formItemLayout">-->
      <!--        <a-input v-model="model.des" />-->
      <!--      </a-form-item>-->

      <a-form-item label="作者" v-bind="formItemLayout" v-if="this.type === '产品知识'">
        <a-input v-model="model.author"/>
      </a-form-item>

      <a-form-item label="副标题" v-bind="formItemLayout" v-if="this.type === '新品上市'">
        <a-input v-model="model.subTitle"/>
      </a-form-item>

      <a-form-item label="跳转链接" v-bind="formItemLayout" v-if="this.type === '新品上市'">
        <a-input v-model="model.link"/>
      </a-form-item>

      <a-form-item label="百科内容" v-bind="formItemLayout">
        <RichTextField v-model="model.content"></RichTextField>
      </a-form-item>
    </a-form>

    <div class="drawer-bootom-button">
      <a-button style="margin-right: .8rem" @click="hide">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>

  const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 18}
  }
  export default {
    data () {
      return {
        isShow: false,
        model: {
          id: null,
          title: null,
          logo: null,
          author: null,
          tag: null,
          content: ""
        },
        type: '产品知识',

        loading: false,
        formItemLayout,
      }
    },
    methods: {
      changeType (value) {
        this.type = value
      },
      show (model = {content:""}) {
        this.isShow = true
        this.model = JSON.parse(JSON.stringify(model))
        if(this.model.id>0){
          this.$get('news/'+this.model.id).then(suc=>{
            this.model=suc.data
          })
        }
      },
      hide () {
        this.type = '产品知识'
        this.isShow = false
      },

      handleSubmit () {
        if (!this.model.logo.length > 0) {
          this.$message.error('上传图片不能为空')
          return
        }
        if (this.model.id > 0) {
          this.edit()
        } else {
          this.add()
        }
      },

      add () {
        this.loading = true
        this.$post('news', this.model).then(r => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(err => {
          this.loading = false
        })
      },
      edit () {
        this.loading = true
        this.$put('news', this.model).then(r => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(err => {
          this.loading = false
        })
      }

    },

  }
</script>

<style>
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .news-image {
    width: 200px;
  }
</style>
